import React from "react";

const Documentation = () => {
  return (
    <div className="lg:mt-[130px] md:mt-[100px] sm:mt-[70px] mt-[65px] max-w-[1350px] px-4 mx-auto">
      <h1 className="text-black lg:text-[56px] md:text-[44px] sm:text-[36px] text-[30px] font-bold lg:leading-[76px] md:leading-[60px] sm:leading-[48px] leading-[36px] font-sathosi text-center ">
        Our Documentation Stack
      </h1>
      <div className="flex justify-center text-left md:-ml-0 -ml-[1.5rem] ">
        <div className="flex sm:gap-[90px] gap-6 items-center flex-wrap sm:justify-start justify-center mt-8">
          {/* first card */}
          <div className="bg-[#00B8D9] relative bg-opacity-10 2xl:w-[380] 2xl:h-[400px] sm:w-[330px] w-[310px]  sm:h-[350px] h-[300px] rounded-xl ">
            <div className="bg-white rounded-xl absolute 2xl:left-[46px] sm:left-[60px] left-[35px] 2xl:top-11 sm:top-6 top-5 px-8  sm:w-[320px] w-[300px] 2xl:h-[320px] sm:h-[300px] h-[260px] sm:py-8 py-6 shadow-xl ">
              <h4 className="relative mb-[65px] sm:text-[32px] text-[27px] font-semibold font-poppins sm:leading-[31px] leading-[25px]   text-black after:content-[''] after:absolute after:w-[20%] after:h-[2px] after:bg-[#00B8D9] after:left-0 after:top-[55px]">
                Writing
              </h4>
              <div className="flex items-center mb-6 sm:mb-8">
                <img src="images/image 18.png" alt="" className="inline-block w-6 mr-4 " />
                <p className="inline-block text-black font-poppins font-medium sm:text-[22px] text-[20px] sm:leading-[31px] leading-[25px]">Google Docs</p>
              </div>
              <div className="flex items-center mb-6 sm:mb-8">
                <img src="images/Microsoft-Word-Icon-PNG 1.png" alt="" className="inline-block w-8 mr-4 " />
                <p className="inline-block text-black font-poppins font-medium sm:text-[22px] text-[20px] sm:leading-[31px] leading-[25px]">Word</p>
              </div>
              <div className="flex items-center mb-6 sm:mb-8">
                <img src="images/image 19.png" alt="" className="inline-block mr-4 w-7 " />
                <p className="inline-block text-black font-poppins font-medium sm:text-[22px] text-[20px] sm:leading-[31px] leading-[25px]">Notion</p>
              </div>
            </div>
          </div>

          {/* second card */}
          <div className="bg-[#EBF7F2] relative  2xl:w-[380] 2xl:h-[400px] sm:w-[330px] w-[310px]  sm:h-[350px] h-[300px] rounded-xl">
            <div className="bg-white rounded-xl absolute 2xl:left-[46px] sm:left-[60px] left-[35px] 2xl:top-11 sm:top-6 top-5 px-8  sm:w-[320px] w-[300px] 2xl:h-[320px] sm:h-[300px] h-[260px] sm:py-8 py-6 shadow-xl ">
              <h4 className="relative mb-[65px] sm:text-[32px] text-[27px] font-semibold font-poppins sm:leading-[31px] leading-[25px] text-black after:content-[''] after:absolute after:w-[20%] after:h-[2px] after:bg-[#00B8D9] after:left-0 after:top-[55px]">
                Screenshots
              </h4>
              <div className="flex items-center mb-6 sm:mb-8">
                <img src="images/Frame (1).png" alt="" className="inline-block mr-4 w-7 " />
                <p className="inline-block text-black font-poppins font-medium sm:text-[22px] text-[20px] sm:leading-[31px] leading-[25px]">Annotely</p>
              </div>
              <div className="flex items-center mb-6 sm:mb-8">
                <img src="images/image 20.png" alt="" className="inline-block w-8 mr-4 " />
                <p className="inline-block text-black font-poppins font-medium sm:text-[22px] text-[20px] sm:leading-[31px] leading-[25px]">Snagit</p>
              </div>
              <div className="flex items-center mb-6 sm:mb-8">
                <img src="images/image 4.png" alt="" className="inline-block mr-4 w-7 " />
                <p className="inline-block text-black font-poppins font-medium sm:text-[22px] text-[20px] sm:leading-[31px] leading-[25px]">Greenshot</p>
              </div>
            </div>
          </div>

          {/* third card */}
          <div className="bg-[#FEF7E7] relative  2xl:w-[380] 2xl:h-[400px] sm:w-[330px] w-[310px]  sm:h-[350px] h-[300px] rounded-xl">
            <div className="bg-white rounded-xl absolute 2xl:left-[46px] sm:left-[60px] left-[35px] 2xl:top-11 sm:top-6 top-5 px-8  sm:w-[320px] w-[300px] 2xl:h-[320px] sm:h-[300px] h-[260px] sm:py-8 py-6 shadow-xl">
              <h4 className="relative mb-[65px] sm:text-[32px] text-[27px] font-semibold font-poppins sm:leading-[31px] leading-[25px] text-black after:content-[''] after:absolute after:w-[20%] after:h-[2px] after:bg-[#00B8D9] after:left-0 after:top-[55px]">
                Demos
              </h4>
              <div className="flex items-center mb-6 sm:mb-8">
                <img src="images/Group 42.png" alt="" className="inline-block w-8 mr-4 " />
                <p className="inline-block text-black font-poppins font-medium sm:text-[22px] text-[20px] sm:leading-[31px] leading-[25px]">Arcade Software</p>
              </div>
              <div className="flex items-center mb-6 sm:mb-8">
                <img src="images/image 5.png" alt="" className="inline-block w-8 mr-4 " />
                <p className="inline-block text-black font-poppins font-medium sm:text-[22px] text-[20px] sm:leading-[31px] leading-[25px]">Loom</p>
              </div>
              <div className="flex items-center mb-6 sm:mb-8">
                <img src="images/image 16.png" alt="" className="inline-block mr-4 w-7 " />
                <p className="inline-block text-black font-poppins font-medium sm:text-[22px] text-[20px] sm:leading-[31px] leading-[25px]">Guide Jar</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Documentation;
